/**
 * 环境配置
 *
 * @type {string}
 */

let baseUrl = 'http://127.0.0.1';

console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "development") {
    baseUrl = "http://192.168.2.131:28095";
} else if (process.env.NODE_ENV === "preview") {
    baseUrl = "https://music.scoder.club";
} else if (process.env.NODE_ENV === "test") {
    // baseUrl = "http://132.232.89.68:37888";
    // baseUrl = "http://192.168.31.193:8080";
    baseUrl = "https://music.scoder.club";
} else if (process.env.NODE_ENV === "production") {
    baseUrl = "https://music.scoder.club";
}

let isProduction = false;

if (process.env.NODE_ENV === "production") {
    isProduction = true;
}

export {
    baseUrl,
    isProduction
}
